<template>
  <div class="page" style="width: 100%;height: 100%;overflow: hidden;">
    <div class="contain">
      <div class="header-logo"><img src="../../assets/img/HS/caigoushangcheng.png" alt=""></div>
      <div class="header-nav">
        <ul v-for="item in  firstClassList.itemList " :key="item.name">
          <li class="header-navLi" @click="hoverHandler(item)">
            {{ item.name }}
            <!-- <div v-show="show">
            <ul class="children-list clearfix">
              <li class="brick-item brick-item-m brick-item-m-2" v-for="(itam, index) in tableData" :key="index">
                <div class="figure figure-img"><img alt="Redmi K70 至尊版" :src="itam.cover" lazy="loaded"></div>
                <h3 class="title">
                  {{ itam.title }}
                </h3>
                <p class="price"><span class="num">{{ itam.showPrice }}</span>元<span>起</span></p>
              </li>
            </ul>
          </div> -->

          </li>
        </ul>
      </div>
      <div class="header-Search">
        <el-input style="width: 220px" placeholder="请输入" v-model="key"></el-input> <el-button slot="append"
          icon="el-icon-search" @click="ToSearch"></el-button>
      </div>
      <div>
        <div class="header-logo1" @click="handleCart"><img src="../../assets/img/HS/caigouche.png" alt="">
        </div>
      </div>
    </div>
    <div style="padding:10px 10px;box-sizing: border-box;width: 100%;height: 100%;overflow: auto;">
      <div class="breadcrumbs">
        <div class="container"><a @click="$router.back()">首页</a><span class="sep">&gt;</span><a>全部结果</a></div>
      </div>
      <div class="search-filter">
        <div class="filter-list"><!---->
          <ul class="item show-less">
            <span class="label">分类：</span>
            <li :class="active == 0 ? 'active' : 'normal'" @click="handleType('', 0)">全部
            </li>
            <li v-for="(item, index) in  firstClassList.itemList " :key="item.name" @click="handleType(item, index + 1)"
              :class="active == index + 1 ? 'active' : 'normal'"> {{ item.name }}</li>
          </ul>
          <div class="fix-position-box"><!----></div>
        </div>
        <div class="filter-list"><!---->
          <ul class="item show-less"><span class="label">分类：</span>
            <li :class="tab == 0 ? 'active' : 'normal'" @click="handleType1('', 0)">全部
            </li>
            <li v-for="(item, index) in  brandList.itemList " :key="item.name" @click="handleType1(item, index + 1)"
              :class="tab == index + 1 ? 'active' : 'normal'"> {{ item.name }} </li>
          </ul>
          <div class="fix-position-box"><!----></div>
        </div>
        <div class="filter-list"><!---->
          <ul class="item show-less"><span class="label">分类：</span>
            <li :class="petType == 0 ? 'active' : 'normal'" @click="handleType2('')">全部
            </li>
            <li :class="petType == 1 ? 'active' : 'normal'" @click="handleType2(1)">猫 </li>
            <li :class="petType == 2 ? 'active' : 'normal'" @click="handleType2(2)">狗</li>
            <li :class="petType == 3 ? 'active' : 'normal'" @click="handleType2(3)">其他</li><!---->
          </ul>
          <div class="fix-position-box"><!----></div>
        </div>
      </div>
      <!-- <ul class="order-list">
        <li class="order-item">新品</li>
        <li class="order-item active">价格<i class="el-icon-top"></i><i class="el-icon-bottom"></i></li>
      </ul> -->
      <ul class="homeList_I">
        <li class="brick-item brick-item-m brick-item-m-2" v-for="(itam, index) in tableData" :key="index"
          @click="getDetail(itam)">
          <div class="figure figure-img"><img width="200" height="200" :src="itam.cover"></div>
          <h3 class="title">
            {{ itam.title }}
          </h3>
          <p class="price"><span class="num">{{ itam.showPrice }}</span>元<span>起</span><!----></p>
        </li>
      </ul>
    </div>
    <div>
      <div class="comView">
        <div class="comL">
          <div>关住我们</div>
          <div class="comImg">
            <img src="https://www.hscwhn.com/static/img/wechat.png" alt="">
            <img src="https://www.hscwhn.com/static/img/dy.png" alt="">
          </div>
        </div>
        <div class="comL">
          <div>服务热线</div>
          <div>
            <div>王璇电话</div>
            <div>周一至周五8.30-18.30</div>
          </div>
        </div>

      </div>
      <div class="comBox">
        <div>全网低价</div>
        <div>花生品质保证</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      firstClassList: [],
      secondClassList: [],
      firstClass: '',
      secondClass: '',
      brandList: [],
      tableData: [],
      key: '',
      show: false,
      hover: false,
      cartList: [],
      active: 0,
      tab: 0,
      brand: '',
      petType: '',
    }
  },
  mounted() {
    console.log(this.$route.query.keyWord, 'this.$route.query.keyWord');
    this.key = this.$route.query.keyWord
    this.getCart()
    this.getBreed()
    this.getBrandData()
    this.getTableData()
  },
  methods: {
    // 搜索分类
    handleType1(item, index) {
      this.tab = index
      if (item) {
        this.brand = item.id
        this.key = ''
      } else {
        this.brand = ''
        this.key = ''
      }
      this.getTableData()
    },
    // 搜索分类
    handleType(item, index) {
      this.active = index
      if (item) {
        this.firstClass = item.id
        this.key = ''
      } else {
        this.firstClass = ''
        this.key = ''
      }
      this.getTableData()
    },
    // 搜索分类
    handleType2(index) {
      this.petType = index
      this.key = ''
      this.getTableData()
    },
    ToSearch() {
      this.keyword = this.key
      this.getTableData()
    },
    hoverHandler(item) {
      this.firstClass = item.id
      this.getTableData()
    },
    handleSearch(data) {
      console.log(data, 'data');
      this.keyword = data
      this.getTableData()
    },
    getTableData() {
      let params = {
        pageIndex: 1,
        pageSize: 10,
        keyword: this.key,
        petType: this.petType,
        brand: this.brand,
        firstCategory: this.firstClass,
        secondCategory: this.secondClass

      }
      this.$http.post("/product/mall/goods/list", params).then(res => {
        if (res.code === 0) {
          console.log(res, 'ppppp');
          this.tableData = res.data.items;
        }
      })
    },
    //获取品牌
    getBrandData() {
      let params = {
        code: 'supplybrand',
        CompanyId: '668638e374396787c0371b60'
      }
      this.$http.get("/cms/System/GetSystemDiction", params).then(res => {
        if (res.code === 0) {
          this.brandList = res.data;
        }
      })
    },
    // 获取类目
    getBreed() {
      let params = {
        code: 'supplyproducttype',
        CompanyId: '668638e374396787c0371b60'
      }
      this.$http.get("/cms/System/GetSystemDiction", params).then(res => {
        if (res.code === 0) {
          this.firstClassList = res.data;
          if (this.firstClass != '') {
            this.firstClassList.itemList.map((item) => {
              if (item.id == this.firstClass) {
                this.secondClassList = item
              }
            })
          }
        }
      })
    },
    // 跳转详情
    getDetail(itam) {
      let query = {
        id: itam.id
      }
      this.$router.push({ path: 'supplyDetail', query })
    },
    // 获取采购车
    getCart() {
      this.$http.get("/product/mall/cart/list").then(res => {
        if (res.code === 0) {
          this.cartList = res.data
          console.log(res, '2323');
        }
      })
    },
    // 进采购单
    handleCart() {
      this.$router.push({ path: 'supplyCart' })
    },
  }
}
</script>
<style scoped lang="scss">
.page {
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  font-size: 14px;

  ul {
    display: flex;
    list-style-type: none;
    align-items: center;
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;
    color: #475669;
  }

  /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }

  .contain {
    width: 1226px;
    height: 100px;
    position: relative;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;

    .header-logo {
      // width: 56px;
      // height: 56px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .header-nav {
      display: flex;
      align-items: center;

      li {
        margin: 0 20px;
      }

      .header-Search {
        float: right;
      }

      .header-navLi {}

      .children-list {
        width: 1226px;
        position: absolute;
        background-color: #fff;
        top: 80px;
        left: 0;
      }

      .brick-item {
        border-right: 1px solid gainsboro;
        background: #fff;
        text-align: center;
        -webkit-transition: all .2s linear;
        transition: all .2s linear;
        font-size: 16px;
        padding: 10px 5px;

        .figure-img {
          width: 160px;
          height: 110px;
          margin: 0 auto;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }


    }

    .header-logo1 {
      width: 56px;
      height: 56px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        position: relative;
      }
    }

    .header-cart {
      width: 250px;
      height: 300px;
      border: 1px solid gray;
      background-color: #fff;
      position: absolute;
      right: -90px;
      top: 60px;
      text-align: left;

    }

    .cartImg {
      width: 60px;
      height: 60px;
      object-fit: cover;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .cartName {
      font-size: 13px;
    }

    .header-btn {
      display: flex;
      justify-content: space-around;
      position: absolute;
      bottom: 0px;
    }
  }

  .breadcrumbs {
    height: 40px;
    font-size: 12px;
    line-height: 40px;
    background: #f5f5f5;
    color: #757575;

    .container {
      width: 1226px;
      margin: 0 auto;
    }
  }

  .search-filter {
    width: 100%;
    background: #fff;

    .filter-list {
      width: 1226px;
      margin: 0 auto;
      padding: 25px 0;

      .show-less {
        li {
          margin: 0 20px;
        }

        .active {
          color: red;
        }
      }
    }
  }

  .order-list {
    width: 1226px;
    margin: 0 auto;

    .order-item {
      float: left;
      margin: 20px 0;
      padding: 0 30px;
      border-left: 1px solid #e0e0e0;
      line-height: 20px;
    }
  }

  .homeList_I {
    width: 1240px;
    margin: 0 auto;
    // display: flex;
    flex-wrap: wrap;
  }

  .brick-item {
    width: 290px;
    height: 380px;
    margin: 10px;
    margin-bottom: 14px;
    padding-top: 47px;
    background: #fff;
    text-align: center;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
  }

  .brick-item:hover {
    z-index: 2;
    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, .1);
    box-shadow: 0 15px 30px rgba(0, 0, 0, .1);
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0)
  }

  .comView {
    width: 100%;
    height: 300px;
    background-color: #9a9898;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .comL {
      text-align: center;
      line-height: 40px;

      .comImg {
        img {
          margin: 10px 20px;
        }

      }
    }
  }

  .comBox {
    width: 100%;
    height: 300px;
    background-color: #f8f5f5;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

}
</style>
